<script>
  import CreateView from '../partials/CreateView.vue'
  import FormInput from '../form/form-input.vue'
  import FormLookup from '../form/form-lookup.vue'

  export default {
    components: {
      CreateView,
      FormInput,
      FormLookup,
    },
    props: {
      rid: {
        type: String,
        required: true
      }
    },
    methods: {
      init () {
        this.$store.dispatch('repositoryconstraint/create/updateItem', {repository: '/api/repositories/' + this.rid})
      }
    }
  }
</script>
<template>
  <create-view
    v-slot:default="slotProps"
    store="repositoryconstraint"
    update-route="RepositoryConstraintUpdate"
    :init="init"
  >
    <form-lookup
      :store="slotProps.store"
      :translation-root="slotProps.translationRoot"
      prop-name="repository"
      class="col-6"
    >
      <template v-slot:selected-item="bundleProps">
        {{ bundleProps.item.name }}
      </template>
      <template v-slot:item="bundleProps">
        {{ bundleProps.item.name }}
      </template>
    </form-lookup>
    <form-lookup
      :store="slotProps.store"
      :translation-root="slotProps.translationRoot"
      prop-name="packageDescriptor"
      class="col-6"
    >
      <template v-slot:selected-item="descriptorProps">
        {{ descriptorProps.item.name }}
      </template>
      <template v-slot:item="descriptorProps">
        {{ descriptorProps.item.name }}
      </template>
    </form-lookup>
    <form-input
      :store="slotProps.store"
      prop-name="constraintText"
      class="col-3"
    />
  </create-view>
</template>
