<script>
  import CreateView from '../partials/CreateView.vue'
  import FormInput from '../form/form-input.vue'
  import tokenMixin from '../form/token-mixin.js'

  import {
    BButton,
    BInputGroupAppend,
  } from 'bootstrap-vue'

  export default {
    components: {
      BButton,
      BInputGroupAppend,
      CreateView,
      FormInput,
    },
    mixins: [
      tokenMixin
    ],
    props: {
      rid: {
        type: String,
        required: true
      }
    },
    methods: {
      init () {
        this.$store.dispatch('repositorytoken/create/updateItem', {repository: '/api/repositories/' + this.rid})
      }
    }
  }
</script>
<template>
  <create-view
    v-slot:default="slotProps"
    store="repositorytoken"
    update-route="RepositoryTokenUpdate"
    :init="init"
  >
    <form-input
      :store="slotProps.store"
      prop-name="name"
      class="col-6"
    />
    <form-input
      :store="slotProps.store"
      prop-name="token"
      class="col-6"
    >
      <b-input-group-append>
        <b-button
          :title="$t('repositorytoken.token.generateTitle')"
          @click="$store.dispatch('repositorytoken/create/updateItem', {token: random()})"
        >
          <font-awesome-icon :icon="iconRandom" />
        </b-button>
      </b-input-group-append>
    </form-input>
  </create-view>
</template>
