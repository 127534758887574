<script>
  import formFieldMixin from './form-field-mixin.js'

  import {
    BFormGroup,
    BFormInput,
    BFormSelect,
  } from 'bootstrap-vue'

  export default {
    components: {
      BFormGroup,
      BFormInput,
      BFormSelect,
    },
    mixins: [
      formFieldMixin
    ],
    computed: {
      type () {
        if (this.value['github-oauth']) {
          return 'github-oauth'
        }
        if (this.value['gitlab-token']) {
          return 'gitlab-token'
        }
        if (this.value['gitea-token']) {
          return 'gitea-token'
        }
        return null
      },
      typeOptions () {
        return [
          {
            // FIXME: translations.
            value: 'github-oauth',
            text: 'Github OAuth'
          },
          {
            // FIXME: translations.
            value: 'gitlab-token',
            text: 'Gitlab Token'
          },
          {
            // FIXME: translations.
            value: 'gitea-token',
            text: 'Gitea Token'
          }
        ]
      },
      typeLabel () {
        return this.$t(`${this.translations}.${this.propName}.type.label`)
      },
      typeDescription () {
        return this.$t(`${this.translations}.${this.propName}.type.description`)
      },
      token () {
        switch (this.type) {
          case 'github-oauth':
            return this.value['github-oauth']['github.com'];
          case 'gitlab-token':
            return this.value['gitlab-token'][this.domain];
          case 'gitea-token':
            return this.value['gitea-token'][this.domain];
        }
        return null
      },
      tokenLabel () {
        return this.$t(`${this.translations}.${this.propName}.token.label`)
      },
      tokenDescription () {
        return this.$t(`${this.translations}.${this.propName}.token.description`)
      },
      domain () {
        switch (this.type) {
          case 'github-oauth':
            return 'github.com';
          case 'gitlab-token':
            return this.value['gitlab-domains'][0] || 'gitlab.com';
          case 'gitea-token':
            return this.value['gitea-domains'][0] || '';
        }
        return null;
      },
      domainLabel () {
        return this.$t(`${this.translations}.${this.propName}.domain.label`)
      },
      domainDescription () {
        return this.$t(`${this.translations}.${this.propName}.domain.description`)
      },
      domainShow () {
        return this.type === 'gitlab-token' || this.type === 'gitea-token'
      }
    },
    methods: {
      updateType (type) {
        switch (type) {
          case 'github-oauth':
            this.update({
              'github-oauth': {[this.domain]: this.token}
            })
            return;
          case 'gitlab-token':
            this.update({
              'gitlab-domains': [this.domain],
              'gitlab-token': {[this.domain]: this.token}
            })
            return;
          case 'gitea-token':
            this.update({
              'gitea-domains': [this.domain],
              'gitea-token': {[this.domain]: this.token}
            })
        }
      },
      updateToken (token) {
        switch (this.type) {
          case 'github-oauth':
            this.update({
              'github-oauth': {[this.domain]: token}
            })
            return;
          case 'gitlab-token':
            this.update({
              'gitlab-domains': [this.domain],
              'gitlab-token': {[this.domain]: token}
            })
            return;
          case 'gitea-token':
            this.update({
              'gitea-domains': [this.domain],
              'gitea-token': {[this.domain]: token}
            })
        }
      },
      updateDomain (domain) {
        switch (this.type) {
          case 'github-oauth':
            this.update({
              'github-oauth': {[domain]: this.token}
            })
            return;
          case 'gitlab-token':
            this.update({
              'gitlab-domains': [domain],
              'gitlab-token': {[domain]: this.token}
            })
            return;
          case 'gitea-token':
            this.update({
              'gitea-domains': [domain],
              'gitea-token': {[domain]: this.token}
            })
        }
      },
      update (value) {
        this.$store.dispatch(`${this.store}/updateItem`, {[this.propName]: value})
      }
    },
  }
</script>
<template>
  <b-form-group
    :description="description"
    :label="label"
    :label-for="inputName"
    :invalid-feedback="violations"
    :state="!isInvalid"
    :readonly="isReadOnly"
    :label-class="labelClasses + 'pt-0'"
    class="mb-0"
  >
    <b-form-group
      :description="typeDescription"
      :label="typeLabel"
      :label-for="inputName + '.type'"
      label-cols-sm="1"
      label-align-sm="right"
    >
      <b-form-select
        :id="inputName + '.type'"
        :value="type"
        :placeholder="placeholder"
        :options="typeOptions"
        @input="updateType($event)"
      />
    </b-form-group>
    <b-form-group
      :description="tokenDescription"
      :label="tokenLabel"
      :label-for="inputName + '.token'"
      label-cols-sm="1"
      label-align-sm="right"
    >
      <b-form-input
        :id="inputName + '.token'"
        :value="token"
        @input="updateToken($event)"
      />
    </b-form-group>
    <b-form-group
      v-if="domainShow"
      :description="domainDescription"
      :label="domainLabel"
      :label-for="inputName + '.domain'"
      label-cols-sm="1"
      label-align-sm="right"
    >
      <b-form-input
        :id="inputName + '.domain'"
        :value="domain"
        @input="updateDomain($event)"
      />
    </b-form-group>
  </b-form-group>
</template>
