<script>
  import formStateMixin from 'bootstrap-vue/esm/mixins/form-state'
  import clickoutMixin from 'bootstrap-vue/esm/mixins/click-out.js'

  import {faBroom, faEdit} from '@fortawesome/free-solid-svg-icons'

  import {
    BButton,
    BDropdown,
    BDropdownDivider,
    BDropdownForm,
    BDropdownItemButton,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
  } from 'bootstrap-vue'

  export default {
    components: {
      BButton,
      BDropdown,
      BDropdownDivider,
      BDropdownForm,
      BDropdownItemButton,
      BInputGroup,
      BInputGroupAppend,
      BInputGroupPrepend,
      BFormInput,
    },
    mixins: [
      formStateMixin,
      clickoutMixin
    ],
    props: {
      placeholder: {type: String, required: true},
      searchPlaceholder: {type: String, required: true},
      optionKey: {type: String, default: '@id'},
      emptyValue: {type: String, default: '-'},
      disabled: {type: Boolean, default: false},
      clearButton: {type: Boolean, default: true},
      editButton: {type: Boolean, default: false},

      options: {type: Array, required: true},
      selected: {type: [String, Number], required: true, default: ''},
      selectedItem: {required: true, validator: prop => typeof prop === 'object' || prop === null},
      searchValue: {type: String, default: ''},
    },
    data () {
      return {
        show: false,
        listenForClickOut: true,
        iconClear: faBroom,
        iconEdit: faEdit,
      }
    },
    computed: {
      availableOptions () {
        return this.options.filter(option => option[this.optionKey] !== this.selected)
      }
    },
    mounted () {
      this.search('')
    },
    methods: {
      select (value) {
        // We need to set empty model to make model watchers react to it
        this.$emit('select', value || null)
      },
      search (search) {
        if (!this.disabled) {
          this.$emit('search', search)
        }
      },
      edit () {
        this.$emit('edit-ref', this.selectedItem)
      }
    }
  }
</script>
<template>
  <b-input-group>
    <b-dropdown
      id="dropdown-text"
      variant="drop-down"
      :disabled="disabled"
      :class="'form-control lookup-select ' + stateClass"
    >
      <template slot="button-content">
        <span v-if="!selectedItem">{{ placeholder }}</span>
        <slot
          v-if="selectedItem"
          name="selected-item"
          :item="selectedItem"
        >
          {{ selectedItem }}
        </slot>
      </template>
      <b-dropdown-form>
        <b-input-group>
          <b-input-group-prepend
            tag="label"
            is-text
            for="search"
            class="sr-only"
          >
            {{ searchPlaceholder }}
          </b-input-group-prepend>
          <b-form-input
            id="search"
            ref="search"
            :value="searchValue"
            :placeholder="searchPlaceholder"
            @input="search"
          />
        </b-input-group>
      </b-dropdown-form>
      <b-dropdown-divider v-if="selected" />
      <b-dropdown-item-button
        v-if="selectedItem"
        :key="'selected_' + selectedItem[optionKey]"
        :active="true"
        @click.stop.prevent="clearButton && select(null)"
      >
        <slot
          name="item"
          :item="selectedItem"
        >
          {{ selectedItem }}
        </slot>
      </b-dropdown-item-button>
      <b-dropdown-divider v-if="options.length > 1" />
      <b-dropdown-item-button
        v-for="item in availableOptions"
        :key="item[optionKey]"
        @click.stop.prevent="select(item[optionKey])"
      >
        <slot
          name="item"
          :item="item"
        >
          {{ item }}
        </slot>
      </b-dropdown-item-button>
    </b-dropdown>
    <b-input-group-append v-if="clearButton || editButton">
      <b-button
        v-if="clearButton"
        variant="danger"
        :disabled="!selectedItem"
        :title="$t('button.clear-selection')"
        @click="select(null)"
      >
        <font-awesome-icon :icon="iconClear" />
      </b-button>
      <b-button
        v-if="editButton"
        variant="success"
        :title="$t('button.edit-referenced')"
        @click="edit"
      >
        <font-awesome-icon :icon="iconEdit" />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>
