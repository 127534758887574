import router from '../../router.js'
import SubmissionError from '../../error/SubmissionError.js'

export default function api (endpoint) {
  const MIME_TYPE = 'application/ld+json'

  return {
    namespaced: true,
    state: {},
    actions: {
      /**
       * Fetch an URI.
       *
       * @param {object}   context
       * @param {Function} context.dispatch
       * @param {object}   context.rootGetters
       * @param {object}   payload
       * @param {string}   payload.uri
       * @param {object}   payload.options
       *
       * @return {Promise}
       */
      fetch ({dispatch, rootGetters}, payload) {
        const {uri, options= {}} = payload

        if (typeof options.headers === 'undefined') Object.assign(options, { headers: new Headers() })

        if (options.headers.get('Accept') === null) {
          options.headers.set('Accept', MIME_TYPE)
        }

        if (options.body !== undefined && !(options.body instanceof FormData) && options.headers.get('Content-Type') === null) {
          options.headers.set('Content-Type', MIME_TYPE)
        }
        if (uri !== 'login_check') {
          let token = rootGetters['authentication/token'];
          if (token) {
            options.headers.set('Authorization', 'Bearer ' + token)
          }
        }
        dispatch('startRequest', null, { root: true });

        return new Promise((resolve, reject) => {
          fetch(new URL(uri, endpoint).toString(), options).then((response) => {
            dispatch('endRequest', null, {root: true});
            if (response.headers.has('Authorization')) {
              const header = response.headers.get('Authorization');
              // Chop 'Bearer ' prefix.
              dispatch('authentication/updateToken', header.substring(7), {root: true});
            }
            if (response.ok) {
              resolve(response)
              return
            }

            if (response.status === 401) {
              router.push({name: 'LoginPage'})
            }

            response
              .json()
              .then((json) => {
                const error = json['hydra:description'] ? json['hydra:description'] : response.statusText
                if (!json.violations) {
                  // Try to fix up the error.
                  const emptyIRI = error.match(/Expected IRI or nested document for attribute "(.*)", "NULL" given\./)
                  if (emptyIRI) {
                    json.violations = [{
                      propertyPath: emptyIRI[1],
                      message: 'This-value-should-not-be-null'
                    }]
                  }
                  const emptyAttribute = error.match(/The type of the "(.*)" attribute must be ".*", "NULL" given"\./)
                  if (emptyAttribute) {
                    json.violations = [{
                      propertyPath: emptyAttribute[1],
                      message: 'This-value-should-not-be-null'
                    }]
                  }
                  if (!json.violations) {
                    reject(new Error(error))
                    return
                  }
                }

                const errors = {_error: error}
                json.violations.map(violation =>
                  Object.assign(errors, {[violation.propertyPath]: violation.message}))

                reject(new SubmissionError(errors))
              })
          })
        })
      },
      post({dispatch}, payload) {
        const {uri, options= {}} = payload
        options.method = 'POST'
        return dispatch('fetch', {uri, options})
      },
      put({dispatch}, payload) {
        const {uri, options= {}} = payload
        options.method = 'PUT'
        return dispatch('fetch', {uri, options})
      },
      delete({dispatch}, payload) {
        const {uri, options= {}} = payload
        options.method = 'DELETE'
        return dispatch('fetch', {uri, options})
      },
      /**
       * Download a file.
       *
       * @param {object}   context
       * @param {Function} context.dispatch
       * @param {object}   payload
       * @param {string}   payload.uri
       * @param {string}   payload.defaultFilename
       *
       * @return {Promise}
       */
      download({dispatch}, payload) {
        const {uri, defaultFilename = 'download.dat'} = payload
        return dispatch('fetch', {uri})
          .then(response => response.json())
          .then((response) => {
            // trick to download store a file having its URL
            const fileURL = response.content
            const a = document.createElement('a')
            a.href = fileURL
            a.target = '_blank'
            a.download = response.filename || defaultFilename
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          })
      },
      /**
       * Trigger an API action (body less POST on an uri).
       *
       * @param {object}   context
       * @param {Function} context.dispatch
       *
       * @param {object}   payload
       * @param {string}   payload.uri
       *
       * @return {Promise}
       */
      trigger({dispatch}, payload) {
        const {uri} = payload
        return dispatch('post', {uri})
      }
    },
    getters: {},
    mutations: {}
  }
}
