<script>
  import CreateView from '../partials/CreateView.vue'
  import FormInput from '../form/form-input.vue'

  export default {
    components: {
      CreateView,
      FormInput,
    },
    computed: {
    },
    watch: {
    }
  }
</script>
<template>
  <create-view
    v-slot:default="slotProps"
    store="user"
    update-route="UserUpdate"
  >
    <form-input
      :store="slotProps.store"
      prop-name="username"
      class="col-3"
    />
    <form-input
      :store="slotProps.store"
      prop-name="email"
      class="col-3"
    />
    <form-input
      :store="slotProps.store"
      prop-name="name"
      class="col-3"
    />
    <form-input
      :store="slotProps.store"
      prop-name="plainPassword"
      type="password"
      class="col-3"
    />
  </create-view>
</template>
