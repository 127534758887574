<script>

  import {
    BButton,
    BCol,
    BTooltip,
  } from 'bootstrap-vue'

  import ListView from '../partials/ListView.vue'
  import ListSortToggler from '../partials/ListSortToggler.vue'

  import {faTicketAlt, faBoxOpen} from '@fortawesome/free-solid-svg-icons'
  import TimeAgo from '../../components/TimeAgo.vue'

  export default {
    components: {
      BButton,
      BCol,
      BTooltip,
      ListView,
      ListSortToggler,
      TimeAgo,
    },
    data () {
      return {
        iconToken: faTicketAlt,
        iconPackages: faBoxOpen,
      }
    }
  }
</script>
<template>
  <list-view
    store="repository"
    add-route="RepositoryCreate"
    edit-route="RepositoryUpdate"
  >
    <template v-slot:header>
      <b-col md="8">
        <list-sort-toggler
          store="repository"
          prop-name="name"
        >
          {{ $t('repository.name.label') }}
        </list-sort-toggler>
      </b-col>
      <b-col
        md="1"
        class="text-right"
      >
        <list-sort-toggler
          store="repository"
          prop-name="updateDate"
        >
          {{ $t('repository.updateDate.label') }}
        </list-sort-toggler>
      </b-col>
      <b-col
        md="1"
        class="text-right"
      >
        <list-sort-toggler
          store="repository"
          prop-name="buildDate"
        >
          {{ $t('repository.buildDate.label') }}
        </list-sort-toggler>
      </b-col>
    </template>
    <template v-slot:item="slotProps">
      <b-col md="8">
        <div class="p-2">
          {{ slotProps.item.name }}
        </div>
      </b-col>
      <b-col
        md="1"
        class="text-right"
      >
        <div
          :id="'update-date-'+slotProps.item.id"
          class="p-2"
        >
          {{ slotProps.item.updateDate ? $d(new Date(slotProps.item.updateDate), 'short') : '-' }}
        </div>
      </b-col>
      <b-tooltip
        :target="'update-date-'+slotProps.item.id"
      >
        <time-ago :time="slotProps.item.updateDate" />
      </b-tooltip>
      <b-col
        md="1"
        class="text-right"
      >
        <div
          :id="'build-date-'+slotProps.item.id"
          class="p-2"
        >
          {{ slotProps.item.buildDate ? $d(new Date(slotProps.item.buildDate), 'short') : '-' }}
        </div>
      </b-col>
      <b-tooltip :target="'build-date-'+slotProps.item.id">
        <time-ago :time="slotProps.item.buildDate" />
      </b-tooltip>
    </template>
    <template v-slot:buttons="slotProps">
      <b-button
        :to="{name: 'RepositoryTokenList', params: {rid: slotProps.item.id}}"
        :title="$t('repository.button.tokens')"
        variant="primary"
      >
        <font-awesome-icon :icon="iconToken" />
      </b-button>
      <b-button
        :to="{name: 'RepositoryConstraintList', params: {rid: slotProps.item.id}}"
        :title="$t('repository.button.constraints')"
        variant="secondary"
      >
        <font-awesome-icon :icon="iconPackages" />
      </b-button>
    </template>
  </list-view>
</template>

