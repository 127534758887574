<script>
  import CreateView from '../partials/CreateView.vue'
  import FormInput from '../form/form-input.vue'
  import FormLookup from '../form/form-lookup.vue'
  import FormRepositoryConfig from '../form/form-repository-config.vue'

  export default {
    components: {
      CreateView,
      FormInput,
      FormLookup,
      FormRepositoryConfig,
    },
  }
</script>
<template>
  <create-view
    v-slot:default="slotProps"
    store="packagedescriptor"
    update-route="PackageDescriptorUpdate"
  >
    <form-input
      :store="slotProps.store"
      prop-name="name"
      class="col-6"
    />
    <form-lookup
      :store="slotProps.store"
      :translation-root="slotProps.translationRoot"
      prop-name="originRepositoryAuth"
      class="col-6"
    >
      <template v-slot:selected-item="authProps">
        {{ authProps.item.name }}
      </template>
      <template v-slot:item="authProps">
        {{ authProps.item.name }}
      </template>
    </form-lookup>
    <form-repository-config
      :store="slotProps.store"
      prop-name="originRepositoryConfig"
      prop-name-type="originRepositoryType"
      prop-name-url="originRepositoryUrl"
      class="col-12"
    />
  </create-view>
</template>
