<script>
  import { mapGetters } from 'vuex'

  import {
    BCol,
  } from 'bootstrap-vue'

  import ListView from '../partials/ListView.vue'
  import ListSortToggler from '../partials/ListSortToggler.vue'

  export default {
    components: {
      BCol,
      ListView,
      ListSortToggler,
    },
    props: {
      rid: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapGetters({
        repository: 'repositorytoken/list/ref_repository',
      })
    },
    methods: {
      init () {
        return this.$store.dispatch('repositorytoken/list/setFilter', {repository: this.rid})
      }
    }
  }
</script>
<template>
  <list-view
    store="repositorytoken"
    add-route="RepositoryTokenCreate"
    edit-route="RepositoryTokenUpdate"
    :init="init"
  >
    <template v-slot:filter />
    <template v-slot:header>
      <b-col md="5">
        <list-sort-toggler
          store="repositorytoken"
          prop-name="name"
        >
          {{ $t('repositorytoken.name.label') }}
        </list-sort-toggler>
      </b-col>
      <b-col md="5">
        <list-sort-toggler
          store="repositorytoken"
          prop-name="token"
        >
          {{ $t('repositorytoken.token.label') }}
        </list-sort-toggler>
      </b-col>
    </template>
    <template v-slot:item="slotProps">
      <b-col md="5">
        <div class="p-2">
          {{ slotProps.item.name }}
        </div>
      </b-col>
      <b-col md="5">
        <div class="p-2">
          {{ slotProps.item.token }}
        </div>
      </b-col>
    </template>
  </list-view>
</template>
