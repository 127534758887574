<script>
  import EditView from '../partials/EditView.vue'
  import FormInput from '../form/form-input.vue'

  export default {
    components: {
      EditView,
      FormInput,
    },
    props: {
      id: {
        type: String,
        required: true
      }
    },
    computed: {
    },
    watch: {
    }
  }
</script>
<template>
  <edit-view
    :id="id"
    v-slot:default="slotProps"
    store="user"
    list-route="UserList"
  >
    <form-input
      :store="slotProps.store"
      prop-name="username"
      class="col-3"
    />
    <form-input
      :store="slotProps.store"
      prop-name="email"
      class="col-3"
    />
    <form-input
      :store="slotProps.store"
      prop-name="name"
      class="col-3"
    />
    <form-input
      :store="slotProps.store"
      prop-name="plainPassword"
      type="password"
      class="col-3"
    />
  </edit-view>
</template>
