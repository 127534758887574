<script>
  import {
    BButton,
    BModal,
  } from 'bootstrap-vue'

  import {props} from 'bootstrap-vue/src/components/button/button.js'

  export default {
    components: {
      BButton,
      BModal
    },
    props: {
      message: { type: String, required: false, default: 'confirmation.message' },
      title: { type: String, required: true },
      ...props
    },
    methods: {
      clicked () {
        this.$refs['modal'].show()
      },
      okClicked () {
        this.$emit('click')
      }
    }
  }
</script>
<template>
  <b-button
    :title="title"
    :active="active"
    :active-class="activeClass"
    :append="append"
    :block="block"
    :disabled="disabled"
    :event="event"
    :exact="exact"
    :exact-active-class="exactActiveClass"
    :href="href"
    :no-prefetch="noPrefetch"
    :pressed="pressed"
    :rel="rel"
    :replace="replace"
    :router-tag="routerTag"
    :size="size"
    :tag="tag"
    :target="target"
    :to="to"
    :type="type"
    :variant="variant"
    @click="clicked"
  >
    <slot />
    <b-modal
      ref="modal"
      centered
      :title="$t('confirmation.title')"
      :ok-title="$t('confirmation.btn-yes')"
      ok-variant="danger"
      :cancel-title="$t('confirmation.btn-no')"
      footer-class="p-2"
      @ok="okClicked"
    >
      <div class="d-block text-center">
        <h3>{{ $t(message) }}</h3>
      </div>
    </b-modal>
  </b-button>
</template>
