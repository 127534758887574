import SubmissionError from '../../error/SubmissionError.js'

export const SET_ERROR = 'SET_ERROR'

export const handleApiError = ({commit, dispatch}, error, violationHandler = 'setViolations') => {
  if (error instanceof SubmissionError) {
    dispatch(violationHandler, error.errors)
    commit(SET_ERROR, error.errors._error)
    return
  }

  commit(SET_ERROR, error)
}

export function apiUrl (operation, values) {
  let result = operation.url
  operation.parameter.map((parameter) => {
    if (values[parameter.name]) {
      result = result.replace(`{${parameter.name}}`, values[parameter.name])
    }
    if (!parameter.required) {
      result = result.replace(`/{${parameter.name}}`, '')
    }
  })
  if (-1 !== result.indexOf('{')) {
    throw new Error('Missing parameters in:' + result)
  }
  return result
}

export function itemEmpty(properties) {
  const item = {}
  Object.keys(properties).map((property) => {
    if (properties[property].type === 'array') {
      item[property] = []
      return
    }
    item[property] = undefined
  })
  return item
}

export function itemPayload(properties, values) {
  const item = {}
  Object.keys(properties).map((property) => {
    if (values[property] === undefined) {
      return
    }
    item[property] = values[property]
  })
  return item
}

export function itemUpdate(item, updated, properties) {
  Object.keys(updated).map((property) => {
    const prop = properties[property]
    if (prop === undefined) {
      console.warn('Unknown property ' + property + ' for update - ignored.')
      return
    }
    if (updated[property] === undefined) {
      throw new Error('Values MUST NOT be undefined, use null instead: ' + property)
    }
    let newValue = undefined
    switch (prop.type) {
      case 'array':
        // FIXME: do we need to walk the elements?
        newValue = updated[property]
        break
      case 'string':
        switch (prop.format) {
          case 'date-time':
            // FIXME: need a way to distinguish between date and datetime.
            newValue = updated[property]
            break
          default:
            newValue = updated[property]
        }
        break
      case 'integer':
        newValue = parseInt(updated[property])
        break
      case 'number':
        newValue = updated[property] ? parseFloat(updated[property]) : (updated[property] === 0 ? 0 : null)
        break
      default:
        throw new Error('Unknown type ' + prop.type + ' for property ' + property)
    }
    if (item[property] !== newValue) {
      item[property] = newValue
    }
  })
}
