<script>

  import {
    BButton,
    BCol,
    BTooltip,
  } from 'bootstrap-vue'

  import ListView from '../partials/ListView.vue'
  import ListSortToggler from '../partials/ListSortToggler.vue'
  import TimeAgo from '../../components/TimeAgo.vue'
  import {faFileArchive} from '@fortawesome/free-solid-svg-icons'

  export default {
    components: {
      BButton,
      BCol,
      BTooltip,
      ListView,
      ListSortToggler,
      TimeAgo,
    },
    data () {
      return {
        iconVersions: faFileArchive,
      }
    }
  }
</script>
<template>
  <list-view
    store="packagedescriptor"
    add-route="PackageDescriptorCreate"
    edit-route="PackageDescriptorUpdate"
  >
    <template v-slot:header>
      <b-col md="4">
        <list-sort-toggler
          store="packagedescriptor"
          prop-name="name"
        >
          {{ $t('packagedescriptor.name.label') }}
        </list-sort-toggler>
      </b-col>
      <b-col md="5">
        <list-sort-toggler
          store="packagedescriptor"
          prop-name="originRepositoryUrl"
        >
          {{ $t('packagedescriptor.originRepositoryConfig.label') }}
        </list-sort-toggler>
      </b-col>
      <b-col
        md="1"
        class="text-right"
      >
        <list-sort-toggler
          store="packagedescriptor"
          prop-name="fetchDate"
        >
          {{ $t('packagedescriptor.fetchDate.label') }}
        </list-sort-toggler>
      </b-col>
    </template>
    <template v-slot:item="slotProps">
      <b-col md="4">
        <div class="p-2">
          {{ slotProps.item.name }}
        </div>
      </b-col>
      <b-col md="5">
        <div class="p-2">
          {{ slotProps.item.originRepositoryUrl }}
        </div>
      </b-col>
      <b-col
        md="1"
        class="text-right"
      >
        <div
          :id="'fetch-date-'+slotProps.item.id"
          class="p-2"
        >
          {{ slotProps.item.fetchDate ? $d(new Date(slotProps.item.fetchDate), 'short') : '-' }}
        </div>
      </b-col>
      <b-tooltip :target="'fetch-date-'+slotProps.item.id">
        <time-ago
          :time="slotProps.item.fetchDate"
          minimal="days"
        />
      </b-tooltip>
    </template>
    <template v-slot:buttons="slotProps">
      <b-button
        :to="{name: 'PackageVersionDescriptorList', params: {id: slotProps.item.id, name: slotProps.item.name}}"
        :title="$t('packagedescriptor.button.versions')"
        variant="primary"
      >
        <font-awesome-icon :icon="iconVersions" />
      </b-button>
    </template>
  </list-view>
</template>

