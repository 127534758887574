<script>
  import { mapGetters } from 'vuex'

  import {
    BCol,
  } from 'bootstrap-vue'

  import {faEdit} from '@fortawesome/free-solid-svg-icons'
  import ListView from '../partials/ListView.vue'
  import ListSortToggler from '../partials/ListSortToggler.vue'
  import PackageDescriptor from '../links/PackageDescriptor.vue'

  export default {
    components: {
      BCol,
      ListView,
      ListSortToggler,
      PackageDescriptor,
    },
    props: {
      rid: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        iconEdit: faEdit,
      }
    },
    computed: {
      ...mapGetters({
        repository: 'repositoryconstraint/list/ref_repository',
        packageDescriptor: 'repositoryconstraint/list/ref_packageDescriptor',
      })
    },
    methods: {
      init () {
        return this.$store.dispatch('repositoryconstraint/list/setFilter', {repository: this.rid})
      }
    }
  }
</script>
<template>
  <list-view
    store="repositoryconstraint"
    add-route="RepositoryConstraintCreate"
    edit-route="RepositoryConstraintUpdate"
    :init="init"
  >
    <template v-slot:filter />
    <template v-slot:header>
      <b-col md="4">
        <list-sort-toggler
          store="repositoryconstraint"
          prop-name="repository.name"
        >
          {{ $t('repositoryconstraint.repository.label') }}
        </list-sort-toggler>
      </b-col>
      <b-col md="4">
        <list-sort-toggler
          store="repositoryconstraint"
          prop-name="packageDescriptor.name"
        >
          {{ $t('repositoryconstraint.packageDescriptor.label') }}
        </list-sort-toggler>
      </b-col>
      <b-col
        md="2"
        class="text-right"
      >
        <list-sort-toggler
          store="repositoryconstraint"
          prop-name="constraintText"
        >
          {{ $t('repositoryconstraint.constraintText.label') }}
        </list-sort-toggler>
      </b-col>
    </template>
    <template v-slot:item="slotProps">
      <b-col md="4">
        <div class="p-2">
          {{ repository(slotProps.item.repository).name }}
        </div>
      </b-col>
      <b-col md="4">
        <package-descriptor
          v-if="!!packageDescriptor(slotProps.item.packageDescriptor).id"
          :package-descriptor="packageDescriptor(slotProps.item.packageDescriptor)"
        />
      </b-col>
      <b-col
        md="2"
        class="text-right"
      >
        <div class="p-2">
          {{ slotProps.item.constraintText }}
        </div>
      </b-col>
    </template>
  </list-view>
</template>
