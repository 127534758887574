<script>
  import {mapGetters} from 'vuex'

  import {
    faSpinner as iconSpinner,
    faChartPie as iconDashboard,
    faCubes as iconRepositories,
    faCube as iconPackageDescriptor,
    faKey as iconPackageDescriptorAuth,
    faToolbox as iconBundles,
    faCog as iconSettings,
    faPowerOff as iconLogout,
    faUsers as iconUserList,
    faUser as iconProfile,
    faComment as iconNotifications,
  } from '@fortawesome/free-solid-svg-icons'

  import {
    BBadge,
    BCollapse,
    BDropdownItem,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BNavbarNav,
    BNavItem,
    BNavItemDropdown,
  } from 'bootstrap-vue'

  import Toasts from './components/Toasts.vue'
  import LogoutCountDown from './components/LogoutCountDown.vue'
  import {loadLanguageAsync, locales, defaultLocale} from './i18n.js'

  export default {
    name: 'PackDis',
    components: {
      BBadge,
      BCollapse,
      BDropdownItem,
      BNavbar,
      BNavbarBrand,
      BNavbarToggle,
      BNavbarNav,
      BNavItem,
      BNavItemDropdown,
      Toasts,
      LogoutCountDown,
    },
    data () {
      return {
        menuBar: {
          left: [
            {name: 'Dashboard', icon: iconDashboard, exact: true},
            {name: 'RepositoryList', icon: iconRepositories},
            {name: 'PackageDescriptor', icon: iconPackageDescriptor},
            {name: 'PackageDescriptorAuth', icon: iconPackageDescriptorAuth},
            {name: 'BundleList', icon: iconBundles},
            {name: 'UserList', icon: iconUserList},
          ],
          right: [
            {
              name: 'Notification',
              icon: iconNotifications,
              exact: true,
              badge: () => {
                return this.unreadNotifications;
              }
            },
          ]
        },
        iconSettings,
        iconSpinner,
        iconLogout,
        iconProfile,
        notificationTimer: null,
        unreadNotifications: 0,
      }
    },
    computed: {
      ...mapGetters({
        username: 'authentication/username',
        userId: 'authentication/userId',
        isLoading: 'isLoading',
        locale: 'locale',
        toasts: 'toasts/items'
      }),
      locales () {
        return locales
      }
    },
    watch: {
      '$route' () {
        this.setTitle()
      },
      username (newValue, oldValue) {
        if (oldValue === newValue) {
          return
        }
        this.updateNotificationRefresh()
      }
    },
    mounted: function () {
      this.setTitle()
      this.$store.dispatch('authentication/refresh')
      this.setLocale(defaultLocale)
      this.updateNotificationRefresh()
    },
    methods: {
      determineTrail (route) {
        return this.$route.matched.some((test) => {
          return test.name === route.name
        }) ? 'router-link-active router-link-trail' : ''
      },
      setTitle () {
        const route = this.$route
        document.title = this.$t('title.' + route.name, route.params) + ' - PackDis!'
      },
      setLocale (locale) {
        loadLanguageAsync(locale).then((locale) => {
          this.$i18n.locale = locale
        })
      },
      updateNotificationRefresh () {
        if (!this.username) {
          if (this.notificationTimer) {
            clearInterval(this.notificationTimer)
            this.notificationTimer = null
            return
          }
          return
        }
        if (this.notificationTimer) {
          return
        }
        this.refreshNotifications()
        this.notificationTimer = setInterval(this.refreshNotifications, 10000)
      },
      refreshNotifications () {
        this.$store.dispatch('api/fetch', {uri: '/api/notification-count.json'})
          .then((response) => {
            return response.json()
          })
          .then(data => {
            if (this.unreadNotifications === data.count) {
              return
            }
            if (this.$route.name === 'NotificationList') {
              // FIXME: this is HACKY!
              this.$refs.content.$children[0].reload()
            }
            this.unreadNotifications = data.count
          })
      }
    }
  }
</script>

<template>
  <div id="app">
    <b-navbar
      toggleable="lg"
      type="light"
      variant="light"
      sticky
    >
      <b-navbar-brand href="/">
        PackDis!
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <b-navbar-nav v-if="username">
          <b-nav-item
            v-for="item in menuBar.left"
            :key="item.name"
            :to="{ name: item.name }"
            :exact="item.exact"
            :link-classes="determineTrail(item)"
          >
            <font-awesome-icon
              :icon="item.icon"
              size="lg"
              :title="$t('navButton.' + item.name)"
            />
            <span class="sr-only">{{ $t('navButton.' + item.name) }}</span>
          </b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-if="isLoading">
            <font-awesome-icon
              :icon="iconSpinner"
              spin
            />
            Loading...
          </b-nav-item>
          <b-nav-item
            v-for="item in menuBar.right"
            :key="item.name"
            :to="{ name: item.name }"
            :exact="item.exact"
            :link-classes="determineTrail(item)"
          >
            <font-awesome-icon
              :icon="item.icon"
              size="lg"
            />
            <b-badge
              v-if="item.badge"
            >
              {{ item.badge() }}
            </b-badge>
            <span class="sr-only">{{ $t('navButton.' + item.name) }}</span>
          </b-nav-item>
          <b-nav-item-dropdown
            v-if="username"
            right
          >
            <!-- Using 'button-content' slot -->
            <template slot="button-content">
              <em>{{ username }}</em>
            </template>
            <b-dropdown-item :to="{name: 'Settings'}">
              <font-awesome-icon
                :icon="iconSettings"
                size="lg"
              />
              {{ $t('navButton.Settings') }}
            </b-dropdown-item>
            <b-dropdown-item :to="{name: 'UserUpdate', params: { id: userId }}">
              <font-awesome-icon
                :icon="iconProfile"
                size="lg"
              />
              {{ $t('navButton.Profile') }}
            </b-dropdown-item>
            <b-dropdown-item @click="$store.dispatch('authentication/logout')">
              <font-awesome-icon
                :icon="iconLogout"
                size="lg"
              />
              {{ $t('navButton.SignOut', {user: username}) }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <toasts />
    <router-view
      ref="content"
      class="content"
    />
    <b-navbar
      class="sticky-bottom"
      variant="light"
    >
      <li class="navbar-text navbar-right">
        <logout-count-down />
      </li>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown
          right
          dropup
          :text="$t('navButton.Language')"
        >
          <b-dropdown-item
            v-for="locale in locales"
            :key="locale"
            :class="($i18n.locale === locale) ? 'router-link-active' : ''"
            @click="setLocale(locale)"
          >
            {{ $t('locale.' + locale) }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>
